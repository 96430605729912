<script>
    const coefficients = [
        [0.21190001350666, 0.04555850290393, 3.49635022285989],
        [0.03507844014766, 0.00754186463175, 0.57879426243641],
        [0.04067412566878, 0.00874493701879, 0.67112307353482],
        [0.00093735802564, 0.00020153197551, 0.01546640742302],
    ]

    const firstJanuary = new Date('2023-01-01').valueOf() / 1000
    const now = new Date().valueOf() / 1000
    const diff = now - firstJanuary

    const values = Array(4)
        .fill()
        .map(() => Array(3).fill(0))

    let totals = Array(4).fill(0)

    // valores iniciales
    for (let i = 0; i < values.length; i++) {
        const row = values[i]
        for (let j = 0; j < row.length; j++) {
            values[i][j] = coefficients[i][j] * diff
            totals[j] += values[i][j]
        }
    }

    setInterval(() => {
        // reseteo los totales
        totals = Array(4).fill(0)

        for (let i = 0; i < values.length; i++) {
            const row = values[i]
            for (let j = 0; j < row.length; j++) {
                values[i][j] += coefficients[i][j]
                totals[j] += values[i][j]
            }
        }
    }, 1000)
</script>

<main>
    <!-- ocupa el lugar de la imagen de fondo -->
    <div class="globo" />
    <article>
        Traemos progreso a la vida a través de acciones concretas que evitan la emisión de Gases de Efecto Invernadero
        (CO<sub><small>2</small></sub>) en la producción de proteína animal.
    </article>
    <article>
        En 2023 ya hemos alcanzado una <b>reducción de emisiones de CO<sub><small>2</small></sub> de:</b>
    </article>
    <article class="indicator">
        <img src="assets/imgs/temperature.png" width="160" alt="icono de globo terraqueo con un termometro" />
        <div>
            {totals[0].toLocaleString('en', { maximumFractionDigits: 0 })} tn CO<sub><small>2</small></sub>e
        </div>
    </article>
    <article class="article-small">Esta reducción es equivalente a:</article>
    <article class="indicator-with-text-bar-container last">
        <div class="indicator-with-text-bar">
            <img class="bar" src="assets/imgs/bar.png" height="240" alt="barra vertical" />
            <div class="indicator-with-text">
                <div class="indicator-small">
                    <img
                        class="vehicle"
                        src="assets/imgs/vehicle.png"
                        height="100"
                        alt="icono de un automovil con el icono de reciclado en el centro"
                    />
                    <div class="number-small number-vehicle">
                        <div>
                            {totals[1].toLocaleString('en', { maximumFractionDigits: 0 })}
                        </div>
                    </div>
                </div>
                <div class="indicator-text">
                    <div class="indicator-title">Vehículos</div>
                    <div class="indicator-caption">que no fueron conducidos al año</div>
                </div>
            </div>
        </div>
        <div class="indicator-with-text-bar">
            <img class="bar" src="assets/imgs/bar.png" height="240" alt="barra vertical" />
            <div class="indicator-with-text">
                <div class="indicator-small">
                    <img
                        src="assets/imgs/hand.png"
                        height="110"
                        alt="icono una planta creciendo de la palma de una mano"
                    />
                    <div class="number-small number-plant">
                        <div>
                            {totals[2].toLocaleString('en', { maximumFractionDigits: 0 })}
                        </div>
                    </div>
                </div>
                <div class="indicator-text">
                    <div class="indicator-title">Árboles</div>
                    <div class="indicator-caption">cultivados durante 10 años</div>
                </div>
            </div>
        </div>
    </article>
</main>

<style>
    .last {
        margin-bottom: 30px;
    }
    .number-small {
        font-size: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .number-plant {
        min-width: 180px;
    }
    .number-vehicle {
        min-width: 160px;
    }
    .bar {
        padding-top: 30px;
    }
    .vehicle {
        padding-top: 20px;
    }
    sub {
        vertical-align: baseline;
        position: relative;
        top: -0.4em;
    }
    sub {
        top: 0.4em;
    }

    .indicator-text {
        display: flex;
        flex-direction: column;
    }
    .indicator-title {
        font-size: 40px;
        font-style: italic;
        font-weight: bold;
    }

    .indicator-caption {
        font-size: 28px;
        font-style: italic;
    }

    .indicator-with-text {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .indicator {
        display: flex;
        align-items: center;
        font-size: 46px;
        justify-content: space-between;
        padding-right: 20%;
    }

    .indicator-small {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .indicator-with-text-bar-container {
        display: flex;
        padding-left: 55px;
        padding-right: 55px;
        align-items: end;
    }

    .indicator-with-text-bar {
        display: flex;
        gap: 8px;
    }

    .globo {
        height: 600px;
    }
    @media (orientation: landscape) {
        .globo {
            height: 530px;
        }
    }
    small {
        font-size: 18px;
    }
    .indicator-small small {
        font-size: 14px;
    }

    main article {
        padding-left: 70px;
        padding-right: 70px;
        font-size: 32px;
        letter-spacing: 0.116rem;
        line-height: 2.5rem;
    }

    main article.article-small {
        font-size: 28px;
    }

    main {
        background-image: url('imgs/background.jpg');
        background-size: cover;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
</style>
